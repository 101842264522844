import './App.css';
import { APP_BASE_URL } from "../src/components/config";
import axios from "axios";
import { useEffect } from "react";
import Layout from './layout/Layout';
import Home from './pages/Home';

function App() {
	useEffect(() => {
		let osType = ''
		let deviceType = ''
		let make = ''
		let browserType = ''

		if (navigator.userAgent.toLowerCase().indexOf("windows") !== -1 ) {
			osType = "windows";
		} else if(navigator.userAgent.toLowerCase().indexOf("iphone") !== -1) {
			osType = "ios";
		} else if(navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
			osType = "android";
		} else if(navigator.userAgent.toLowerCase().indexOf("x11") !== -1) {
			osType = "unix";
		} else if(navigator.userAgent.toLowerCase().indexOf("mac") !== -1) {
			osType = "mac";
		} else {
			osType = "web";
		}

		if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) {
			browserType = 'opera';
		} else if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
			browserType = 'chrome';
		} else if(navigator.userAgent.indexOf("Firefox") !== -1 ){
			browserType = 'firefox';
		} else if(navigator.userAgent.indexOf("Brave") !== -1 ){
			browserType = 'brave';
		} else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
			browserType = 'ie';//crap
		} else if(navigator.userAgent.indexOf("Safari") !== -1) {
			browserType = 'safari';
		} else {
			browserType = 'application';
		}

		if(navigator.userAgent.indexOf("Mobile") !== -1 ) {
			deviceType = 'mobile';
		} else if(navigator.userAgent.indexOf("iPad") !== -1 ) {
			deviceType = 'tablet';
		} else {
			deviceType = 'desktop';
		}

		if(navigator.userAgent.indexOf("iPhone") !== -1 ) {
			make = 'iPhone';
		} else if(navigator.userAgent.indexOf("Android") !== -1 ) {
			make = 'android';
		} else {
			make = 'web';
		}

		axios.post(`${APP_BASE_URL}/auth/lannmarket/getsessionid`, {
			"osType": osType,
			"deviceType": deviceType,
			"make": make,
			"model": '1',
			"browserType": browserType,
			"version": "220"
		})
		.then(({ data = { } }) => {
			const { result = { } } = data;
			localStorage.setItem('token', result.sessionID);
		})
		.catch(err => {
			console.log(err);
		})

	}, [])

	return (
		<Layout>
			{/* <head>
				<title>{"Lann Market"}</title>
				<meta name="title" content={"Lann Market"} />
				<meta
					name="description"
					content={"Buy, Sell, And Trade Skins Easier And Faster. All Deals Are Secured With The Highest Level Protection Methods."}
				/>
				<meta property="og:title" content={"Lann Market"} />
				<meta
					property="og:description"
					content={"Buy, Sell, And Trade Skins Easier And Faster. All Deals Are Secured With The Highest Level Protection Methods."}
				/>
				<meta property="og:image" content={""} />
				<meta property="og:image:width" content="900" />
				<meta property="og:image:height" content="472" />
				<meta property="og:url" content={"lann.market"} />
				<meta property="og:type" content="website" />
				<link rel="icon" href="/favicon.ico" />

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={"lann.market"} />
				<meta property="twitter:title" content={"Lann Market"} />
				<meta property="twitter:description" content={"Buy, Sell, And Trade Skins Easier And Faster. All Deals Are Secured With The Highest Level Protection Methods."} />
				<meta property="twitter:image" content={""} />
				<meta property="twitter:image:width" content="800" />
				<meta property="twitter:image:height" content="418" />
			</head> */}
			<Home />
		</Layout>
	);
}

export default App;
