import logo from "../../assets/img/LannLogo.svg";
import fb from "../../assets/img/brand/fb.svg";
import instagram from "../../assets/img/brand/instagram.svg";

const Footer  = () => {
    return (
        <footer className="relative bg-gradient-to-t from-[#47a4329a] py-8 z-10">
            <div className="container mx-auto px-4 flex flex-wrap text-left lg:text-left">
                <div className="w-full lg:w-6/12 px-4">
                    <img className="h-12" src={logo} alt="logo" />
                    <h5 className="text-lg mt-4 mb-2 text-white cursor-default">
                        Find us on any of these platforms, we respond 1-2 business days.
                    </h5>
                    <div className="flex mt-6 lg:mb-0 mb-6">
                        <a href="https://www.facebook.com/lannmarket" target="_blank" rel="noopener noreferrer" className="bg-[#47A432] hover:scale-105 ease-in-out duration-300 shadow-lg h-10 w-10 rounded-lg outline-none mr-2">
                            <img className="m-auto mt-[9px]" src={fb} alt="fb" />
                        </a>
                        <a href="https://www.instagram.com/lann_market" target="_blank" rel="noopener noreferrer" className="bg-[#47A432] hover:scale-105 ease-in-out duration-300 shadow-lg h-10 w-10 rounded-lg outline-none mr-2">
                            <img className="m-auto mt-2" src={instagram} alt="instagram" />
                        </a>
                        {/* <a href="" className="bg-white text-pink-400 shadow-lg h-10 w-10 rounded-lg outline-none mr-2">
                            <img src="" alt="fb" />
                        </a>
                        <a href="" className="bg-white text-blueGray-800 shadow-lg h-10 w-10 rounded-lg outline-none mr-2">
                            <img src="" alt="fb" />
                        </a> */}
                    </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                    <div className="flex flex-wrap items-top mb-6">
                        {/* <div className="w-full lg:w-4/12 px-4 ml-auto">
                            <span className="block text-white text-lg font-semibold mb-2 cursor-default">Main</span>
                            <ul className="list-unstyled">
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://www.creative-tim.com/presentation?ref=njs-profile">About Us</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://blog.creative-tim.com?ref=njs-profile">Blog</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://www.github.com/creativetimofficial?ref=njs-profile">Github</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://www.creative-tim.com/bootstrap-themes/free?ref=njs-profile">Free Products</a>
                                </li>
                            </ul>
                        </div> */}
                        <div className="w-full lg:w-4/12 px-4 mt-2">
                            <span className="block text-white text-lg font-semibold mb-2 cursor-default">Terms</span>
                            <ul className="list-unstyled">
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://lann.market/privacy.html">Privacy Policy</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://lann.market/term.html">Terms of use</a>
                                </li>
                                {/* <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://creative-tim.com/privacy?ref=njs-profile">Refund Policy</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://creative-tim.com/contact-us?ref=njs-profile">Price Policy</a>
                                </li> */}
                            </ul>
                        </div>
                        {/* <div className="w-full lg:w-4/12 px-4">
                            <span className="block text-white text-lg font-semibold mb-2 cursor-default">Company</span>
                            <ul className="list-unstyled">
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://github.com/creativetimofficial/notus-js/blob/main/LICENSE.md?ref=njs-profile">About us</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://creative-tim.com/terms?ref=njs-profile">Contact us</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://creative-tim.com/privacy?ref=njs-profile">Our blog</a>
                                </li>
                                <li>
                                    <a className="text-white hover:text-[#47A432] block pb-2 text-sm" href="https://creative-tim.com/contact-us?ref=njs-profile">Discover</a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
