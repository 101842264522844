import logo from "../../assets/img/LannLogo.svg"

const Header  = () => {
    return (
        <nav className="border-b-[0.5px] border-[#8A8AA0] px-4 lg:px-6 py-2.5 bg-[#14141F] z-10">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
                <a href="/" className="flex items-center">
                    <img src={logo} className="mr-6 h-8 md:h-12" alt="Logo" />
                </a>
                {/* <div className="flex items-center lg:order-2">
                    <a href="#" className="text-white font-medium rounded-full text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 bg-[#47A432] hover:bg-[#64df48]">Get started</a>
                    <a href="#" className="text-white focus:ring-2 font-medium rounded-full text-xs px-4 lg:px-12 py-2 lg:py-2.5 mr-2 border border-[#47A432] hover:bg-[#47A432] focus:outline-none focus:ring-[#47A432]">Sign in with STEAM</a>
                </div> */}
                {/* <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
                    <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 rounded bg-[#47A432] lg:bg-transparent lg:text-[#47A432] lg:p-0 text-white" aria-current="page">Home</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 border-b lg:border-0 lg:p-0 text-white lg:hover:text-[#47A432] hover:text-[#47A432] border-gray-700">Market</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 border-b lg:border-0 lg:p-0 text-white lg:hover:text-[#47A432] hover:text-[#47A432] border-gray-700">Inventory</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 border-b lg:border-0 lg:p-0 text-white lg:hover:text-[#47A432] hover:text-[#47A432] border-gray-700">Sell</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 border-b lg:border-0 lg:p-0 text-white lg:hover:text-[#47A432] hover:text-[#47A432] border-gray-700">Buy</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pr-4 pl-3 border-b lg:border-0 lg:p-0 text-white lg:hover:text-[#47A432] hover:text-[#47A432] border-gray-700">Help</a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </nav>
    );
}

export default Header;
