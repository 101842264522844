// import bookmark from "../../assets/img/bookmark.svg"
import { getFormattedNumber } from "../util";

const ItemCard  = ({ pic, category, name, skin, price, type }) => {
    return (
        <div className={`flex-shrink-0 relative p-3.5 mx-2 mb-6 overflow-hidden bg-zinc-700 rounded-2xl max-w-xs shadow-lg ${category !== 'Normal' ? 'border border-[#cf6a32]' : ''}`}>
            {/* <span className="absolute bg-black rounded-full text-white text-xs font-bold p-1.5 gap-1 justify-center leading-none flex">
                <img src={bookmark} alt="bookmark" />
            </span> */}
            <div className="pt-6 px-10 flex justify-center">
                <img className="w-48" src={`https://steamcommunity-a.akamaihd.net/economy/image/${pic}`} alt="item" />
            </div>
            <div className="mt-8">
                <span className="text-white text-2xl font-bold leading-none flex pb-2">
                    {getFormattedNumber(price)}₮
                </span>
                {/* <span className="text-zinc-400 pb-2 flex">
                    Steam price $1400
                </span> */}
                <div className="flex justify-between mb-1">
                    <span className="text-sm text-white">{name}</span>
                    { type !== null ?
                        <span className="bg-[#47A432] rounded-full text-white text-xs font-bold px-2.5 py-1 leading-none flex justify-center">
                            {type}
                        </span>
                        :
                        <></>
                    }
                </div>
                <span className="font-bold text-white">
                    {skin}
                </span>
            </div>
        </div>
    );
}

export default ItemCard;
