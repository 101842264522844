import { useEffect, useState } from "react";
import { APP_BASE_URL } from "../components/config";
import logo from "../assets/img/Vector.svg";
import logo2 from "../assets/img/Vector-1.svg";
import ItemCard from "../components/card/ItemCard";
import appStore from "../assets/img/appStore.svg";
import playStore from "../assets/img/playStore.svg";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
// import SideMenu from "../components/sideMenu";

function Home() {
	const [ items, setItems ] = useState([]);
	const token = localStorage.getItem('token')

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(`${APP_BASE_URL}/landing?pageSize=10`, {
				method: "GET",
				// headers: {
				// 	'session-token': token,
				// }
			});
			const data = await response.json();
			setItems(data.result.docs)
		}
		void fetchData();
	}, [token]);

	const responsive = {
		superLargeDesktop: {
		  	// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 8
		},
		desktop: {
			breakpoint: { max: 3000, min: 1280 },
			items: 5
		},
		smallDesktop: {
			breakpoint: { max: 1280, min: 1024 },
			items: 4
		},
		tablet: {
			breakpoint: { max: 1024, min: 600 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 600, min: 0 },
			items: 1
		}
	};

	return (
		<div className="p-8 relative">
			{/* <SideMenu /> */}
			<div className="relative">
				<img className="hidden lg:block absolute left-0" src={logo} alt="logo" />
				<img className="hidden lg:block absolute right-0" src={logo2} alt="logo2" />
			</div>
			<div className="relative flex flex-col lg:flex-row justify-between my-16">
				<div className="flex relative flex-col gap-5 lg:pl-[5%] pb-16 lg:py-32">
					<div className="text-white text-4xl lg:text-6xl font-bold cursor-default">
						Discover, find,
					</div>
					<div className="text-[#008542] text-4xl lg:text-6xl font-bold cursor-default">
						Sell extraordinary
					</div>
					<div className="text-white text-4xl lg:text-6xl font-bold cursor-default">
						CS:GO SKINS
					</div>
					<div className="text-white capitalize text-sm lg:text-xl cursor-default">
						Buy, sell, and trade skins easier and faster. 
						All deals are secured with the highest level protection methods.
					</div>
					<div className="flex gap-4 mt-5 justify-center lg:justify-start">
						<a href="https://play.google.com/store/apps/details?id=mn.mezorn.lannmarket" target="_blank" rel="noopener noreferrer">
							<img className="hover:scale-110 ease-in-out duration-300" src={playStore} alt="playStore" />
						</a>
						<a href="https://apps.apple.com/us/app/lann-market/id1633405929" target="_blank" rel="noopener noreferrer">
							<img className="hover:scale-110 ease-in-out duration-300" src={appStore} alt="appStore" />
						</a>
					</div>
				</div>
				<div className="self-center pr-[4%] cursor-default">
					<span className="text-white text-5xl lg:text-8xl font-bold">Safe.Fast.</span><span className="text-[#008542] text-5xl lg:text-8xl font-bold">EZ</span>
				</div>
			</div>
			<Carousel
				responsive={responsive}
				infinite={true}
				autoPlay={true}
  				autoPlaySpeed={3000}
				containerClass="mb-8"
			>
				{ items?.map((data, index) => {
					return (
						<ItemCard pic={data.icon_url} category={data.category} name={data.type} skin={data.name} price={data.price} type={data.exterior} key={index} />
					)
				})}
			</Carousel>
		</div>
	);
}

export default Home;
